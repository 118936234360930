<template>
  <div class="download-page">
    <div class="download-title">
      <img src="@/assets/common/logo-title.png" class="title-img" />
      <span class="title-text">振合网商户后台</span>
    </div>
    <div class="download-text">
      <img src="@/assets/common/app-text.png" class="text-img" />
    </div>
    <div class="download-link">
      <a :href="ios" :download="ios" v-if="false" @click="download">
        <div class="link">
          <img src="@/assets/common/ios.png" class="link-img" />
          <span>iPhone下载</span>
        </div>
      </a>
      <a :href="android" :download="android" @click="download">
        <div class="link">
          <img src="@/assets/common/Android.png" class="link-img" />
          <span>Android下载</span>
        </div>
      </a>
    </div>
    <div class="back-img">
      <img src="@/assets/common/img-back.png" class="img-back" />
    </div>
    <div class="mask-layer" v-if="show">
      <img src="../../assets/my/point-to.png" class="point-to" />
      <img
        src="../../assets/my/cancel_button@2x.png"
        class="know"
        @click="close"
      />
    </div>
  </div>
</template>

<script>
import { getAppVersion } from "@/api/common.js";
import tools from "../../common/js/tools";
export default {
  name: "download-app",
  data() {
    return {
      show: false,
      android: "",
      ios: "",
    };
  },
  mounted() {
    this.getAppVersion();
    if (tools.isWx()) {
      this.show = true;
    }
  },
  methods: {
    async getAppVersion() {
      const res = await getAppVersion();
      if (res.code * 1 === 1) {
        if (!tools.isWx()) {
          this.android = res.data.app_url;
          this.ios = res.data.ios_url;
        } else {
          this.android = "javascript:void(0)";
          this.ios = "javascript:void(0)";
        }
      }
    },
    close() {
      this.show = false;
    },
    download() {
      if (tools.isWx()) {
        this.show = true;
        return false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.download-page {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  .download-title {
    display: flex;
    align-items: center;
    padding-left: 18px;
    height: 66px;
    background: rgba(255, 255, 255, 0.39);
    border-bottom: 1px solid #ebebeb;
    .title-img {
      display: block;
      width: 32px;
      height: 32px;
    }
    .title-text {
      font-weight: 600;
      font-size: 19px;
      margin-left: 11px;
    }
  }
  .download-text {
    margin: 38px 0 21px 19px;
    .text-img {
      display: block;
      width: 360px;
      height: 92px;
    }
  }
  .download-link {
    display: flex;
    padding-left: 18px;
    .link {
      display: flex;
      align-items: center;
      width: 138px;
      height: 44px;
      background: rgb(32, 30, 31);
      opacity: 1;
      border-radius: 6px;
      .link-img {
        display: block;
        width: 24px;
        height: 24px;
      }
      span {
        font-size: 17px;
        color: #ffffff;
        margin-left: 6px;
      }
      &:last-child {
        margin-left: 11px;
      }
    }
  }
  .back-img {
    position: absolute;
    bottom: 0;
    left: 0;
    .img-back {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}
.mask-layer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  .point-to {
    position: absolute;
    top: 0;
    right: 0;
    width: 271px;
    height: 331px;
  }
  .know {
    position: absolute;
    top: 368px;
    right: 96px;
    width: 185px;
    height: 58px;
  }
}
</style>
